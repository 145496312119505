import React from 'react';

import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {colors} from '@stryd/react-ui';

const DocumentLinkAnchor = styled.a`
  color: ${colors.themes.dark.textHighlight};

  &:hover {
    text-decoration: underline;
  }
`;

const TermsContainer = styled.div`
  width: 90%;
  font-size: 11px;
`;

export const TermsOfService: React.FC<{style?: React.CSSProperties}> = ({
  style,
}) => {
  // eslint-disable-next-line
  const renderLinkChunk = (href: string) => (chunk: string) => {
    return (
      <DocumentLinkAnchor href={href} rel="noopener noreferrer" target="_blank">
        {chunk}
      </DocumentLinkAnchor>
    );
  };

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <TermsContainer style={style}>
      <FormattedMessage
        id="terms.implicit"
        defaultMessage="By purchasing this Product and/or Membership, you agree to our <terms>Terms of Use</terms>, <warranty>Product Limited Warranty</warranty>, and <privacy>Privacy Policy</privacy>."
        values={{
          terms: renderLinkChunk('https://www.stryd.com/terms-of-use'),
          warranty: renderLinkChunk('https://www.stryd.com/warranty'),
          privacy: renderLinkChunk('https://www.stryd.com/privacy'),
        }}
      />
    </TermsContainer>
  );
};

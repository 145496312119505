import {SignUpSteps} from 'src/features/auth/components';

import {PageContainer, PageContentContainer} from './layout';

export const SignUpPageTemplate: React.FC = () => {
  return (
    <PageContainer>
      <PageContentContainer>
        <SignUpSteps />
      </PageContentContainer>
    </PageContainer>
  );
};

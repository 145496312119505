import {SupportedLocale, ValidRegionCode} from 'src/config';

/**
 * Locales that are compatible with Apple scripts.
 * See here: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
 */
export type AppleLocale =
  | 'ar_SA'
  | 'ca_ES'
  | 'cs_CZ'
  | 'da_DK'
  | 'de_DE'
  | 'el_GR'
  | 'en_GB'
  | 'en_US'
  | 'es_ES'
  | 'es_MX'
  | 'fi_FI'
  | 'fr_CA'
  | 'fr_FR'
  | 'hr_HR'
  | 'hu_HU'
  | 'id_ID'
  | 'it_IT'
  | 'iw_IL'
  | 'ja_JP'
  | 'ko_KR'
  | 'ms_MY'
  | 'nl_NL'
  | 'no_NO'
  | 'pl_PL'
  | 'pt_BR'
  | 'pt_PT'
  | 'ro_RO'
  | 'ru_RU'
  | 'sk_SK'
  | 'sv_SE'
  | 'th_TH'
  | 'tr_TR'
  | 'uk_UA'
  | 'vi_VI'
  | 'zh_CN'
  | 'zh_HK'
  | 'zh_TW';

type GetAppleLocaleFn = (
  lang: SupportedLocale,
  region: ValidRegionCode
) => AppleLocale;

/**
 * Maps a `SupportedLocale` into a function to create an `AppleLocale`. Typechecking here makes sure we always transform our locales into an apple compatible one
 */
const appleLocaleGetterMap: Record<SupportedLocale, GetAppleLocaleFn> = {
  en: (_, region) => {
    switch (region) {
      case 'uk':
        return 'en_GB';
      case 'us':
        return 'en_US';
      default:
        return 'en_US';
    }
  },
  fr: (_, region) => {
    switch (region) {
      case 'eu':
        return 'fr_FR';
      case 'ca':
        return 'fr_CA';
      default:
        return 'fr_FR';
    }
  },
  de: () => 'de_DE',
  es: () => 'es_ES',
};

export const getAppleLocale = (
  lang: SupportedLocale,
  region: ValidRegionCode
) => {
  const getLocale = appleLocaleGetterMap[lang] ?? appleLocaleGetterMap['en'];
  return getLocale(lang, region);
};

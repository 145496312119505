import styled from 'styled-components';

import {colors} from '@stryd/react-ui';

import {Link} from '../Link';

const AltActionLinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.8rem;

  & a {
    color: ${colors.themes.dark.textHighlight};
  }

  & a:hover {
    text-decoration: underline;
  }
`;

export interface AltPageLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  message: React.ReactNode;
  linkText: React.ReactNode;
  href: string;
}

export const AltPageLink: React.FC<AltPageLinkProps> = (props) => {
  const {message, linkText, href, ...anchorAttrs} = props;

  return (
    <AltActionLinkContainer>
      <p>
        {message}{' '}
        <span>
          <Link {...anchorAttrs} href={href}>
            {linkText}
          </Link>
        </span>
      </p>
    </AltActionLinkContainer>
  );
};

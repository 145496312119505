import {useState} from 'react';

import {
  useIntl,
  FormattedMessage,
  MessageDescriptor,
  defineMessage,
} from 'react-intl';
import styled from 'styled-components';

import {BorderOnlyActionButton, dimensions, Spinner} from '@stryd/react-ui';
import {isValidEmail} from '@stryd/util-lib';

import {ErrorContainer} from '../ErrorContainer';
import {PasswordInput} from '../PasswordInput';
import {TextInput} from '../TextInput';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${dimensions.twicePadding};
  }
`;

const InputsContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const SubmitButton = styled(BorderOnlyActionButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;

  > * + * {
    margin-left: ${dimensions.defaultPadding};
  }
`;

const emptyEmailMessageDescriptor: MessageDescriptor = defineMessage({
  id: 'errors.requiredEmail',
  defaultMessage: 'This is required. Enter a valid email address.',
});

const invalidEmailMessageDescriptor: MessageDescriptor = defineMessage({
  id: 'errors.invalidEmail',
  defaultMessage: "It looks like this isn't a valid email address.",
});

const emptyPasswordMessageDescriptor: MessageDescriptor = defineMessage({
  id: 'errors.requiredPassword',
  defaultMessage: 'This is required. Enter a password.',
});

const validateEmail = (email: string): {err: MessageDescriptor | null} => {
  if (!email) {
    return {err: emptyEmailMessageDescriptor};
  }

  if (!isValidEmail(email.trim())) {
    return {err: invalidEmailMessageDescriptor};
  }

  return {err: null};
};

export interface SuccessFormSubmitResult {
  ok: true;
}

export interface ErrorFormSubmitResult {
  ok: false;
  emailError?: string;
  passwordError?: string;
  formError?: string;
}

export type FormSubmitResult = SuccessFormSubmitResult | ErrorFormSubmitResult;

export interface EmailPasswordFormProps {
  onSubmit: (email: string, password: string) => Promise<FormSubmitResult>;
}

export const EmailPasswordForm: React.FC<EmailPasswordFormProps> = (props) => {
  const {onSubmit} = props;
  const intl = useIntl();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | undefined>();
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const [formError, setFormError] = useState<string | undefined>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setEmailError('');
    setPasswordError('');
    setFormError('');
    setSubmitting(true);

    const result = await onSubmit(email.trim(), password.trim());

    if (!result.ok) {
      setEmailError(result.emailError);
      setPasswordError(result.passwordError);
      setFormError(result.formError);
    }

    setSubmitting(false);
    setHasSubmitted(true);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(e.target.value);
  };

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!hasSubmitted && !e.target.value) {
      return;
    }

    const {err} = validateEmail(e.target.value);

    if (err) {
      setEmailError(intl.formatMessage(err));
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    setPassword(e.target.value);
  };

  const handlePasswordBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!hasSubmitted && !e.target.value) {
      return;
    }

    if (!e.target.value) {
      setPasswordError(intl.formatMessage(emptyPasswordMessageDescriptor));
    }
  };

  const formDisabled =
    submitting || !email || !password || !!emailError || !!passwordError;

  return (
    <Form onSubmit={handleSubmit}>
      {formError && <ErrorContainer role="alert">{formError}</ErrorContainer>}

      <InputsContainer>
        <TextInput
          label={intl.formatMessage({
            id: 'auth.emailLabel',
            defaultMessage: 'Email Address',
          })}
          value={email}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          errorMessage={emailError}
          spellCheck={false}
          aria-required={true}
        />
        <PasswordInput
          label={intl.formatMessage({
            id: 'auth.passwordLabel',
            defaultMessage: 'Password',
          })}
          value={password}
          onChange={handlePasswordChange}
          onBlur={handlePasswordBlur}
          errorMessage={passwordError}
          spellCheck={false}
          aria-required={true}
        />
      </InputsContainer>

      {/* TODO add forgot password, conditionally allow via prop */}

      <SubmitButton disabled={formDisabled} type="submit">
        <span>
          <FormattedMessage id="actions.continue" defaultMessage="Continue" />
        </span>
        {submitting && <Spinner size={'1rem'} />}
      </SubmitButton>
    </Form>
  );
};

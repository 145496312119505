import Head from 'next/head';

import {SignInPageTemplate} from './SignInPageTemplate';
import {SignUpPageTemplate} from './SignUpPageTemplate';

type AuthPageType = 'signIn' | 'signUp';

const authPageMap: Record<AuthPageType, React.ComponentType> = {
  signIn: SignInPageTemplate,
  signUp: SignUpPageTemplate,
};

interface CheckoutAuthPageTemplateProps {
  authPageType: AuthPageType;
}

export const CheckoutAuthPageTemplate: React.FC<CheckoutAuthPageTemplateProps> =
  ({authPageType}) => {
    const AuthPage = authPageMap[authPageType];

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
        </Head>
        <AuthPage />
      </>
    );
  };
